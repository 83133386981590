<template>
  <div id="refunds">
    <b-row class="mb-5">
      <b-col>
        <h1>{{ $t('Refunds') }}</h1>
        <h5 class="text-primary">
          {{ $t('refunds.shopRefunds') }}
        </h5>
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="primary"
          @click="routeToViewWizardRefunds"
        >
          <feather-icon
            icon="FolderPlusIcon"
            class="mr-50"
          />
          {{ $t('refunds.addRefunds') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col class="d-flex justify-content-end">
        <div class="flatpickr d-flex align-items-center">
          <b-form-select
            v-model="vendor"
            class="mr-2"
            :disabled="isEmpty(allVendors)"
          >
            <b-form-select-option :value="null">
              {{ $t('filterVend') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="coun in allVendors"
              :key="coun.node.id"
              :value="coun.node.id"
            >
              {{ coun.node.vendorName }}
            </b-form-select-option>
          </b-form-select>
          <b-form-select
            v-model="vUser"
            class="mr-2"
            :disabled="isEmpty(allVusers)"
          >
            <b-form-select-option :value="null">
              {{ $t('filterByClient') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="coun in allVusers"
              :key="coun.node.id"
              :value="coun.node.id"
            >
              {{ cleanUsername(coun.node.username) }}
            </b-form-select-option>
          </b-form-select>
          <b-form-select
            v-model="creator"
            class="mr-2"
            :disabled="isEmpty(allProfiles)"
          >
            <b-form-select-option :value="null">
              {{ $t('filterByadmin') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="coun in allProfiles"
              :key="coun.node.id"
              :value="coun.node.id"
            >
              {{ cleanUsername(coun.node.username) }}
            </b-form-select-option>
          </b-form-select>

          <b-form-select
            v-model="filterStatus"
            class="mr-2"
          >
            <b-form-select-option :value="null">
              {{ $t('subscriptions.status') }}
            </b-form-select-option>
            <b-form-select-option
              v-for="stat in Object.keys(status)"
              :key="stat"
              :value="stat"
            >
              {{ status[stat] }}
            </b-form-select-option>
          </b-form-select>
          <flat-pickr
            id="pickr"
            v-model="filterDate"
            class="form-control mr-2"
            :placeholder="$t('filtDate')"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d'
            }"
          />

          <a
            class="input-button"
            title="toggle"
            @click="clickPickr()"
          >
            <feather-icon
              icon="CalendarIcon"
              size="22"
              class="mr-1"
            />

          </a>

          <a
            v-if="filterDate.length != 0"
            class="input-button"
            title="clear"
            @click="filterDate = ''"
          >
            <feather-icon
              icon="XIcon"
              size="22"
              class="mr-1"
            />

          </a>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          ref="refInvoiceListTable"
          :items="allRefunds"
          :busy="isBusy"
          responsive
          :fields="tableColumns"
          primary-key="id"
          select-mode="single"
          show-empty
          :empty-text="$t('emptyText')"
          selectable
          @row-selected="rowSelect"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>{{ $t('load') }}...</strong>
            </div>
          </template>
          <template #cell(user_id)="data">
            <div>
              <b-link
                :to="{
                  name: 'final-users-edit',
                  params: { id: data.item.node.user.id }
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                @{{ cleanUsername(data.item.node.user.username) }}
              </b-link>
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              <b-badge
                pill
                :variant="data.item.node.status == 'PEN' ? 'warning' : data.item.node.status == 'COM' ? 'success' : 'danger'"
                class="text-capitalize"
              >
                {{ status[data.item.node.status] }}
              </b-badge>
            </div>
          </template>
          <template #cell(reason)="data">
            <div id="">
              <span class="acortar_parrafo">
                {{ data.item.node.reason }}
              </span>
            </div>
          </template>
          <template #cell(vendor_id)="data">
            <div>
              {{ data.item.node.vendor.vendorName }}
            </div>
          </template>
          <template #cell(amount)="data">
            <div>
              {{ format(format(data.item.node.amount)) }} {{ currencyType[currency] }}
            </div>
          </template>
          <template #cell(payment)="data">
            <div>
              {{ methodPayment[data.item.node.paymentGateway] }}
            </div>
          </template>
          <template #cell(createdAt)="data">
            <div>
              {{ fechaFormato(data.item.node.createdAt) }}
            </div>
          </template>
          <template #cell(refund_creator)="data">
            <div>
              <b-link
                :to="{
                  name: 'administrators-setting',
                  params: { id: data.item.node.refundCreator.id }
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.node.refundCreator.username }}
              </b-link>
            </div>
          </template>
          <template #cell(paid)="data">
            <div>
              <b-button
                variant="success"
                :disabled="data.item.node.status != 'PEN'"
                @click="checkFee(data.item.node)"
              >
                {{ $t('Pagar') }}
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex ">
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
          @input="fetchData"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BSpinner,
  BButton,
  BTable,
  BFormSelect,
  BBadge,
  BLink,
  BFormSelectOption,
  BPagination,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  messageError, cleanUsername, utf8ToB64, isEmpty, showToast, getVendorProfileData,
} from '@/store/functions'

import axios from '@axios'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import { getUserData } from '@/auth/utils'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BButton,
    BTable,
    BFormSelectOption,
    BLink,
    BFormSelect,
    BBadge,
    flatPickr,
    BPagination,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: getUserData(),
      refunds: null,
      isBusy: false,
      allRefunds: [],
      vendor: null,
      allVendors: [],
      isEmpty,
      filterDate: '',
      currency: null,
      tableColumns: [
        { key: 'user_id', label: this.$t('Cliente') },
        { key: 'status', label: this.$t('editContent.status') },
        { key: 'reason', label: this.$t('Razón') },
        { key: 'vendor_id', label: this.$t('Vendedor') },
        { key: 'amount', label: this.$t('a devolver') },
        { key: 'payment', label: this.$t('methodPayment') },
        { key: 'createdAt', label: this.$t('message.tableHeader.date') },
        { key: 'refund_creator', label: this.$t('Creador') },
        { key: 'paid', label: this.$t('Pagar') },
      ],
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
        isInternal: false,
      },
      methodPayment: {
        STR: 'Stripe',
        APP: 'Apple InAppPurchase',
        GOW: 'Google InAppPurchase',
      },
      status: {
        PEN: this.$t('pending'),
        COM: this.$t('completed'),
        CAN: this.$t('cancelled'),
      },
      filterStatus: null,
      rows: 0,
      perPage: 20,
      allVusers: [],
      vUser: null,
      allProfiles: [],

      creator: null,
      currentPage: 1,
    }
  },
  watch: {
    filterDate() {
      this.fetchData()
    },
    filterStatus() {
      this.fetchData()
    },
    vendor() {
      this.fetchData()
    },
    vUser() {
      this.fetchData()
    },
    creator() {
      this.fetchData()
    },
  },
  async mounted() {
    if (this.userData.profile.isVendor) {
      await getVendorProfileData(this.userData.profile.id).then(result => {
        if (result) {
          this.vendor = result.id
        }
      })
      this.tableColumns = this.tableColumns.filter(item => item.key !== 'vendor_id' && item.key !== 'paid')
    } else {
      this.getVendor()
      this.fetchData()
    }
    this.getVusers()
    this.getProfiles()
    this.getPayMethod()
  },
  methods: {
    checkFee(data) {
      const paymentId = data.order.chargeToken
      const { stripeSecretKey } = data.user.client

      const query = `
                    {
                        feeDetailsStripe(user:"${stripeSecretKey}",
                        paymentId:"${paymentId}") {
                        status
                        }
                    }
                        `
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)
          const details = JSON.parse(result.data.data.feeDetailsStripe.status)
          if (details) {
            const { fee } = details
            const total = details.amount
            this.paidRefund(data, paymentId, stripeSecretKey, fee, total)
          } else showToast(result.data.errors[0].message, 2, this)
        })
        .catch(err => {
          showToast(this.$t('errorPagos'), 2, this)

          console.log(err)
        })
    },
    paidRefund(data, paymentId, stripeSecretKey, fee, total) {
      let amount = null

      try {
        amount = data.amount
        if (amount && paymentId && stripeSecretKey) {
          const feePercent = this.getFeeAmount(fee, amount, total)
          this.$swal({
            title: this.$t('paidRefund'),
            text: this.$t('secureReturn', {
              amount: data.amount, currency: this.currencyType[this.currency], fee: feePercent / 100, currencyType: this.currencyType[this.currency],
            }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('yNext'),
            cancelButtonText: this.$t('dataGeneric.cancel'),
            // cancelButtonText: "Indicar url",
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.isConfirmed) {
              const query = `
                    {
                        refundUserStripe(user:"${stripeSecretKey}",
                        amount:"${amount * 100}",
                        reason:"requested_by_customer",
                        paymentIntent:"${paymentId}") {
                        status
                        }
                    }
                        `
              axios
                .post('', { query })
                .then(res => {
                  messageError(res, this)
                  const idPaid = JSON.parse(res.data.data.refundUserStripe.status)
                  if (idPaid) this.updateNewData(idPaid.payment_intent, data.id, data.order, amount)
                  else showToast(res.data.errors[0].message, 2, this)
                })
                .catch(err => {
                  showToast(this.$t('errorReaPag'), 2, this)

                  console.log(err)
                })
            }
          }).catch(() => {

          })
        } else {
          showToast(this.$t('inforef'), 2, this)
        }
      } catch (err) {
        console.log(err)
        showToast(this.$t('infoRef'), 2, this)
      }
    },
    getFeeAmount(fee, amount, total) {
      const am = amount * 100
      const x = (am * fee) / total
      return Math.trunc(x)
    },
    updateNewData(paymentId, refundId, order, refunded) {
      // TODO

      /*
                      · Cambiar el estado de la devolución a completo
                      · guardar id del pago de la devolución en la devolución
                      · Cambiar estado de la orden original a orden devuelta
                      · Quitar de la orden padre la cantidad devuelta
                  */

      const resMoney = (order.totalRefunded == null ? refunded : order.totalRefunded + refunded)
      /*
                  let x = 0
                  let y = 0

                  if (order.totalAmount > refunded) {
                      x = order.totalAmount
                      y = refunded
                  } else {
                      x = refunded
                      y = order.totalAmount
                  }
                  let resMoney = x - y */

      const query = `
                        mutation{
                            updateRefund(id:"${refundId}",input:{status:COM,paymentId:"${paymentId}"}){
                                refund{
                                    id
                                }
                            }
                            updateOrder(id:"${order.id}",input:{status:A_8,totalRefunded:${resMoney}}){
                                order{
                                id

                                }
                            }

                        }
                        `
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)
          this.fetchData()
        })
        .catch(err => { console.log(err) })
    },
    format(num) {
      const reg = /\d{1,3}(?=(\d{3})+$)/g
      return (`${num}`).replace(reg, '$&.')
    },
    cleanUsername,

    rowSelect(data) {
      const { id } = data[0].node
      this.$router.push({ name: 'edit-refunds', params: { id } })
    },

    routeToViewWizardRefunds() {
      this.$router.push({ name: 'create-refunds' })
    },

    fetchData() {
      this.isBusy = !this.isBusy

      const query = `
            {
                allRefund(
                    first:${this.perPage},
                    ${this.vendor != null ? `vendor:"${utf8ToB64(`id:${this.vendor}`)}"` : ''}
                    offset:${this.perPage * (this.currentPage - 1)},
                    ${this.filterDate !== '' ? `createdAt:"${this.filterDate}",` : ''}
                    ${this.filterStatus != null ? `status:"${this.filterStatus}",` : ''}
                    ${this.vUser != null ? `user:"${utf8ToB64(`id:${this.vUser}`)}",` : ''}
                    ${this.creator != null ? `refundCreator:"${utf8ToB64(`id:${this.creator}`)}",` : ''}


                    client: "${this.userData.profile.client.id}") {
                        totalCount
                        edges {
                        node {
                            id
                            status
                            user {
                                id
                                username
                                client{
                                    id
                                    name
                                    stripeSecretKey
                                }
                            }
                            amount
                            paymentGateway
                            reason
                            date
                            paymentId
                            order {
                                id
                                chargeToken
                                totalRefunded
                                totalAmount
                            }
                            refundCreator {
                                id
                                username
                            }
                            client {
                                id
                                name
                            }
                            vendor {
                                id
                                vendorName
                                name
                                lastName
                                vendorImage
                                imageVendorUrl
                            }
                            createdAt
                            }
                        }
                    }
                }
            `
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)

          this.allRefunds = result.data.data.allRefund.edges

          this.rows = result.data.data.allRefund.totalCount
          this.isBusy = !this.isBusy
        })
        .catch(err => { console.log(err) })
    },
    getProfiles() {
      const query = `{
                allProfiles(client:"${this.userData.profile.client.id}",refund:true) {
                    edges {
                    node {
                        id
                        username
                        userRefund {
                        edges {
                            node {
                            id
                            }
                        }
                        }
                    }
                    }
                }
                }`

      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.allProfiles = result.data.data.allProfiles.edges
        }).catch(err => {
          console.log(err)
        })
    },
    getVusers() {
      const query = `{
                allVusers(client:"${this.userData.profile.client.id}",refund:true) {
                    edges {
                    node {
                        id
                        username
                        userRefund {
                        edges {
                            node {
                            id
                            }
                        }
                        }
                    }
                    }
                }
                }`

      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.allVusers = result.data.data.allVusers.edges
        }).catch(err => {
          console.log(err)
        })
    },
    getVendor() {
      const query = `{
            allVendors(client:"${this.userData.profile.client.id}",refund:true) {
                totalCount
                edges {
                    node {
                        id
                        name
                        vendorName

                        vendorRefund {
                        edges {
                            node {
                            id
                            }
                        }
                        }
                    client {
                        id
                        name
                    }
                    }
                }
                }
            }`

      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.allVendors = result.data.data.allVendors.edges
        }).catch(err => {
          console.log(err)
        })
    },
    clickPickr() {
      document.getElementById('pickr').click()
    },

    getPayMethod() {
      axios
        .post('', {
          query: `
                query{
                client(id:"${this.userData.profile.client.id}"){
                    externalPayMethod
                    currency
                }
            }
        `,
        })
        .then(res => {
          messageError(res, this)
          this.currency = res.data.data.client.currency
        })
        .catch(() => {
          // this.externalPayMethod = null;
        })
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    getImage(data) {
      let path = '@/assets/images/backend/nocover.jpeg'
      if (data.length !== 0) {
        data.forEach(element => {
          path = element.http_path
        })
      }
      return path
    },
    errorImg(e) {
      e.target.src = fileError
    },
    buildImageUrl(folder) {
      return folder.imageVendorUrl == null || folder.imageVendorUrl.length === 0
        ? noCover
        : folder.imageVendorUrl
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#refunds .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#refunds .cardContent img {
  height: 11vw;
  object-fit: cover;
  /*width: 100%;
        height:  100%;
        object-fit: cover;*/
}

#refunds .card-body {
  padding: 1rem;
}

#refunds .card-body h4 {
  font-size: 1rem !important;
}

#refunds .card-header {
  padding: 1rem;
}

#refunds .card-header h4 {
  font-size: 1rem !important;
}

#refunds .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;

  justify-content: space-between;
}

/*
#refunds #acortar {
    overflow: hidden;
    text-overflow: ellipsis;
}

#refunds #acortarPadre {
    overflow: hidden;
    white-space: nowrap;
} */

#acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

.acortar_parrafo {
  display: block;
  display: -webkit-box;
  font-size: 14px;
  line-height: 1.4;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
